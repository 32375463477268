import HeroBtnGroup from './HeroBtnGroup';

const Hero = () => {
  return (
    <>
      <h1 className='hero-heading fw-bold mt-45 mb-40 md-mt-20 text-white text-center'>
        Best hiring starts with great conversations.
        <br />
        Assess candidates' soft skills with
        <br /> expert-designed questions.
      </h1>
      <p className='text-xlg mb-50 lg-mb-30 pe-xxl-4 text-white text-center fw-semibold'>
        Spot the right candidate with great interviewing questions. <br />
        Evaluate soft skills for any role and seniority level.
      </p>
      <HeroBtnGroup />
    </>
  );
};

export default Hero;
